.MuiInputBase-input{
    font-size: 12px !important;
    border:"0px";
    background:  #efefef !important;
    height: 22px !important;
  }
.MuiOutlinedInput-notchedOutline{
    border-width: 0px !important;
}
.MuiStack-root {
    padding-top: 0px !important;
    flex-direction: column !important;
    margin-left: 0px;
}
.MuiFormControl-root {
    max-width: auto !important;    
}
.MuiButtonBase-root {
    padding: 0px !important;
}