.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 120px;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  border: 0px solid #d9d9d9;
  border-radius: 4px;
}
